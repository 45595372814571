import React, { FC } from 'react';
import { BaseText } from './BaseText';
import { BaseTextProps } from './types';
import styles from './Heading.module.scss';
interface HeadingProps extends BaseTextProps {
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
}

const Heading: FC<HeadingProps> = ({ as = 'h1', ...rest }) => {
  return <BaseText htmlStringTag={as} className={styles[as]} {...rest} />;
};

export default Heading;
