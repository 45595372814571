import { createElement, FC } from 'react';
import { getColorFromString, getFontWeightFromString } from './utils';
import { BaseTextProps } from './types';
import { getMarginFromProps } from 'components/shared/utils';

import cx from 'classnames';

import styles from './BaseText.module.scss';

export const BaseText: FC<BaseTextProps> = ({
  htmlStringTag = 'p',
  children,
  color = 'black',
  weight,
  letterSpacing,
  fontSize,
  align,
  style,
  isLink,
  className,
  ...rest
}) => {
  const colorStyle = getColorFromString(color) as string;
  const fontWeightStyle = getFontWeightFromString(weight);
  const marginStyle = getMarginFromProps(rest);

  const joinedClassName = cx(
    styles.baseText,
    {
      [styles.link]: isLink,
    },
    className
  );

  return createElement(
    htmlStringTag,
    {
      className: joinedClassName,
      style: {
        fontWeight: fontWeightStyle,
        color: colorStyle,
        textAlign: align,
        letterSpacing,
        fontSize,
        ...marginStyle,
        ...style,
      },
      ...rest,
    },
    children
  );
};
